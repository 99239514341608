import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import ShareButtons from '../components/share'
import "./twitter-post.scss"
import switchCategory from '../utils/switchCategory.jsx';
import reactStringReplace from "react-string-replace";
import AdSense from 'react-adsense'
import SEO from '../components/seo'
import PrevNext from '../utils/prevNext.jsx'
import Helmet from "react-helmet"
import GetRakutenAdsense from '../utils/getAffiliate'
import  ThemeContext  from '../components/context/ThemeContext';
import GoHome from '../utils/templatefooter.jsx' 
import RelatedPosts from '../components/relatedPosts'

const regExp = /(https?:\/\/\S+)/g;
export default ({
  data: {
    allTwitterJson: { nodes }
  }
  , pageContext }) => {

   var twitterUrl
   var decoration
  return (
    <>
  <Helmet
    bodyAttributes={{
        class: 'globalnody'
    }}
    />
    <ThemeContext.Consumer>
        {theme => (
    <Layout page="posts" tabnumber={switchCategory(nodes[0].genre[0].rough_genre)} themeContext={theme}>
      <SEO title={nodes[0].title　+ " | 話題に反応！"} description={nodes[0].title} image={nodes[0].top_image} template='twitter'/>
      <main className="twitter">
        <header className="twitter--header">
        <p className="twitter--header__topimage">
          <img
              src ={nodes[0].top_image}
              className="twitter--header__topimage__img"
             />
          </p>
          <h1>{nodes[0].title}</h1>

          <ShareButtons shareTitle={nodes[0].title} shareUrl={nodes[0].fields.slug}></ShareButtons>
        </header>
        {
          Object.values(nodes[0].tweet).map(
            (onetwi,index) =>
            {
                decoration = onetwi.font_color + " " + onetwi.font_size + " " + onetwi.font_bold;
                twitterUrl = "https://twitter.com/" + onetwi.screen_name + "/status/" + onetwi.tweet_id;

            return  (
                <article key={index} className="twitter--onetweet">
                  <a className="twitter--onetweet--header" href={"https://twitter.com/" + onetwi.screen_name}>
                    <li className="twitter--onetweet--header__icon">
                      <img src={onetwi.profile_image_url} style={{width: "30px",height: "30px"}} />
                    </li>
                    <li className="twitter--onetweet--header__id" >
                      {onetwi.screen_name + " " + onetwi.name }
                    </li>
                  </a>
                  <div className="twitter--onetweet--text">
                      {reactStringReplace(onetwi.tweet_text, regExp, (match, i) => (
                        <a href={match}>{match}</a>
                      ))}
                    </div>
                  {
                    onetwi.tweet_images.map((imgUrl,imageindex) => {
                      if ( imgUrl.includes('.mp4')) {
                        return(
                          <video controls className="twitter--onetweet--image">
                            <source src={imgUrl} type="video/mp4" />
                          </video> 
                        )       
                        }
                        else{
                      return (
                        <img  key={imageindex} src={imgUrl} className="twitter--onetweet--image"/>
                      )
                        }
                    }
                    )
                  }
                  {
                    onetwi.tweet_videos.map((imgUrl,imageindex) => {
                      if(onetwi.tweet_videos !== []){
                        return(
                          <video controls className="twitter--onetweet--image">
                            <source src={imgUrl} type="video/mp4" />
                          </video> 
                        )
                      } 
                    }
                    )
                  }
                  {onetwi.ad_word == "" ? (<></>):(<><GetRakutenAdsense adWord={onetwi.ad_word}/></>)}
                </article>
              )
                }
          )
        }
        {nodes[0].ad_words_all == "" ? (<></>):(<><GetRakutenAdsense adWord={nodes[0].ad_words_all }/></>)}
        <AdSense.Google client="ca-pub-9496221164080653" slot="1273467658" format="auto"/> 
        <RelatedPosts relatedPosts={pageContext.relatedPosts}/>
        <PrevNext prev={pageContext.prev} next={pageContext.next} />
      </main>
    </Layout>
        )}
        </ThemeContext.Consumer>
     </>
  )
}

export const query = graphql`
query($slug: String!)  {
    allTwitterJson(filter: {fields: {slug: {eq: $slug}}}) {
        nodes {
            title
            date(formatString: "YYYY.MM.DD h:mm")
            categories
            genre{
              rough_genre
              strict_genre
            }
            fields {
                slug
            }
            tweet {
                tweet_text
                tweet_id
                screen_name
                profile_image_url
                tweet_images
                tweet_videos
                name
                font_size
                font_color
                font_bold
                ad_word
              }
            top_image
            ad_words_all
        }
      }
    }
`
